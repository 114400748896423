import React from 'react'
import marked from 'marked'
import { Helmet } from 'react-helmet'
import { PageProps, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { transparentize } from 'polished'
import { Product } from '../typings'
import { Layout } from '../components/Layout'
import { Hero } from '../components/Hero'
import { Buttons } from '../components/Button'
import { Instruction } from '../components/Instruction'
import { Banner } from '../components/Banner'

const zahlen = ['null', 'eins', 'zwei', 'drei', 'vier', 'fünf', 'sechs', 'sieben', 'acht', 'neun', 'zehn', 'elf', 'zwölf']

interface ProjectGuidePagePros {
  strapiProduct: Pick<Product, 'name' | 'logo' | 'color' | 'guide'>
}

const ProjectGuidePage = ({ data }: PageProps<ProjectGuidePagePros>) => {
  const product = data.strapiProduct
  const guide = product.guide
  const logo = getImage(guide.logo?.localFile) ?? getImage(product.logo?.localFile)
  const image = getImage(guide.hero.image?.localFile)
  const lightenColor = product.color && transparentize(0.9, product.color)

  return (
    <Layout>
      <Helmet titleTemplate="meinAPOVERLAG - %s">
        <meta name="description" content={guide.hero.description} />
        <title>{product.name}</title>
      </Helmet>
      <Hero bgColor={product.color && transparentize(0.9, product.color)}>
        <div className="w-full lg:w-screen-lg">
          <div className="lg:w-1/2 flex flex-col items-center lg:items-start">
            {logo && <GatsbyImage className="mb-8 flex-shrink-0" image={logo} alt={product.name} />}
            <h1 className="max-w-xl text-2xl lg:text-3xl text-center lg:text-left font-bold mb-4">{guide.hero.title}</h1>
            <div
              className="markdown custom-list text-lg lg:text-xl text-center lg:text-left"
              dangerouslySetInnerHTML={{ __html: marked(guide.hero.description) }}
            />
            {guide.hero.buttons?.length && (
              <div className="flex flex-col md:flex-row md:justify-center md:flex-wrap gap-2 md:gap-4 mt-8">
                <Buttons buttons={guide.hero.buttons} className="sm:w-60" />
              </div>
            )}
          </div>
        </div>
        {image && (
          <GatsbyImage
            style={{ position: 'absolute' }}
            className="ml-16 hidden lg:block rounded-md shadow-md absolute top-1/3 left-1/2"
            image={image}
            alt="Screenshot"
          />
        )}
      </Hero>
      {guide.instructions?.length && (
        <>
          <h2 style={{ borderColor: product.color }} className="max-w-max text-2xl lg:text-3xl font-bold my-8 pb-1 border-b-2">
            In {guide.instructions.length <= 12 ? zahlen[guide.instructions.length] : guide.instructions.length} Schritten zum{' '}
            {product.name}
          </h2>
          <div className="space-y-8 mb-8 sm:mb-12 md:mb-16 lg:mb-20">
            {guide.instructions.map((instruction, index) => (
              <Instruction key={instruction.id} step={index + 1} instruction={instruction} color={lightenColor} />
            ))}
          </div>
        </>
      )}
      {guide.banner && <Banner banner={guide.banner} bgColor={lightenColor} />}
    </Layout>
  )
}

export default ProjectGuidePage

export const query = graphql`
  query ($slug: String!) {
    strapiProduct(slug: { eq: $slug }) {
      name
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 64, placeholder: NONE)
          }
        }
      }
      color
      guide {
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 64, placeholder: NONE)
            }
          }
        }
        hero {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600, aspectRatio: 1.5, layout: FIXED)
              }
            }
          }
          buttons {
            id
            text
            url
            type
          }
        }
        instructions {
          id
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, aspectRatio: 1.5)
              }
            }
          }
        }
        banner {
          title
          description
          buttons {
            id
            text
            url
            type
          }
        }
      }
    }
  }
`
