import React from 'react'
import marked from 'marked'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Instruction as InstructionType } from '../typings'
import { Card } from './Card'

interface InstructionProps {
  step: number
  instruction: InstructionType
  color?: string
}

export const Instruction = ({ step, instruction, color }: InstructionProps) => {
  const image = getImage(instruction.image?.localFile)

  return (
    <Card className="p-8 flex flex-col md:flex-row items-center gap-6 md:gap-8 lg:gap-12">
      <div
        style={{ backgroundColor: color }}
        className="h-24 md:h-28 w-24 md:w-28 flex items-center justify-center flex-shrink-0 rounded-full text-xl font-bold bg-primary-background select-none"
      >
        Schritt {step}
      </div>
      <div className="order-2 md:order-1 flex-1 lg:max-w-md">
        <p className="text-xl font-bold mb-2">{instruction.title}</p>
        <div
          className="markdown custom-list text-gray-600 leading-snug"
          dangerouslySetInnerHTML={{ __html: marked(instruction.description) }}
        />
      </div>
      {image && <GatsbyImage className="order-1 md:order-2 rounded-md shadow-md flex-1" image={image} alt={instruction.title} />}
    </Card>
  )
}
